import axios from '@/http/axios'

let xmlHttp;

export function getServerTime() {
  try {
    //FF, Opera, Safari, Chrome
    xmlHttp = new XMLHttpRequest();
  } catch (err1) {
    //IE
    try {
      xmlHttp = new ActiveXObject('Msxml2.XMLHTTP');
    } catch (err2) {
      try {
        xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
      } catch (eerr3) {
        //AJAX not supported, use CPU time.
        alert("AJAX not supported");
      }
    }
  }
  xmlHttp.open('HEAD', window.location.href.toString(), false);
  xmlHttp.setRequestHeader("Content-Type", "text/html");
  xmlHttp.send('');
  return xmlHttp.getResponseHeader("Date");
}

export function truncateAccountingDatabase () {
  return axios({
    url: 'v1/truncate-all',
    method: 'post'
  })
}

export function getSetting () {
  return axios({
    url: 'v1/settings',
    method: 'get'
  })
}

export function getAppSetting() {
  return axios({
    url: 'v1/settings/app',
    method: 'get'
  })
}

export function getCompanySetting() {
  return axios({
    url: 'v1/settings/company',
    method: 'get'
  })
}

export function getMessengerSetting() {
  return axios({
    url: 'v1/settings/messenger',
    method: 'get'
  })
}

export function getStoreroomSetting() {
  return axios({
    url: 'v1/settings/storeroom',
    method: 'get'
  })
}

export function getSaleSetting() {
  return axios({
    url: 'v1/settings/sale',
    method: 'get'
  })
}

export function getPurchaseSetting() {
  return axios({
    url: 'v1/settings/purchase',
    method: 'get'
  })
}

export function uploadAvatar (avatar) {
  const data = new FormData()
  data.append('avatar', avatar)

  return axios({
    url: 'v1/admin/settings/upload-avatar',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post'
  })
}

export function editSetting (data) {
  return axios({
    url: 'v1/settings',
    data,
    method: 'patch'
  })
}

export function editAppSetting(payload) {
  return axios({
    url: 'v1/settings/app',
    data: payload,
    method: 'patch'
  })
}

export function editCompanySetting(payload) {
  return axios({
    url: 'v1/settings/company',
    data: payload,
    method: 'patch'
  })
}

export function editFinancialSetting(payload) {
  return axios({
    url: 'v1/settings/financial',
    data: payload,
    method: 'patch'
  })
}

export function editShippingSetting(payload) {
  return axios({
    url: 'v1/settings/shipping',
    data: payload,
    method: 'patch'
  })
}

export function editPrintSetting(payload) {
  return axios({
    url: 'v1/settings/print',
    data: payload,
    method: 'patch'
  })
}

export function editMessengerSetting(payload) {
  return axios({
    url: 'v1/settings/messenger',
    data: payload,
    method: 'patch'
  })
}

export function editLocaleSetting(payload) {
  return axios({
    url: 'v1/settings/locale',
    data: payload,
    method: 'patch'
  })
}

export function editStoreroomSetting(payload) {
  return axios({
    url: 'v1/settings/storeroom',
    data: payload,
    method: 'patch'
  })
}

export function editSaleSetting(payload) {
  return axios({
    url: 'v1/settings/sale',
    data: payload,
    method: 'patch'
  })
}

export function editPurchaseSetting(payload) {
  return axios({
    url: 'v1/settings/purchase',
    data: payload,
    method: 'patch'
  })
}
