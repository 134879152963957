<template>
  <div class="custom-input custom-validate-input">
    <label :class="[{'is-not-valid': !data.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <input type="text"
             :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
             @input="handleInputValue, $emit('input', data)"
             @click="$emit('click')"
             @contextmenu="handleContextMenuClicked($event)"
             @keydown.enter="handleKeydownEnter"
             :autocomplete="false"
             :name="Math.random()"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="data.value">
    </label>
    <span class="invalid-text-helper" v-if="!data.isValid && this.data.value.length > 0" >
      {{ invalidText }}
    </span>

  </div>
</template>

<script>
export default {
  name: 'customValidateInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return false }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    align: {
      type: String,
      default: () => { return 'left' }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return false }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    invalidText: {
      type: String,
      default: () => { return '' }
    },

    classes: {
      type: [Object, Array, String],
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
  },
  methods: {
    handleInputValue () {
      this.data.value = this.data.value.toString().replace(this.regex, '')
      this.validateValue()
      this.$emit('input', {value: this.data.value.toString().replaceAll(',', ''), isValid: this.data.isValid})
    },
    validateValue () {
      if (this.regex !== null && this.data.value !== '') {
        this.data.isValid = this.regex.test(this.data.value)
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else {
        this.data.isValid = this.data.value.toString().length > 0
      }
    },
    initValues () {
      this.data.value = this.value.value
      this.validateValue()
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.data.value,
          paste: true
        },
        event: event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-validate-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    background: #ffffff;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;
      text-align: right;

      &.disabled {
        opacity: 0.75;
      }
    }
  }

  .invalid-text-helper {
    font-size: 11px;
    color: #b00000;
  }

}
</style>
