<template>
  <div class="custom-check-box">
    <input type="checkbox"
           :checked="checked"
           @click="!rowSelectable ? setChecked() : ''"
           style="opacity: 0; display: none"
           :disabled="value.hasOwnProperty('locked') && value.locked">

    <span class="check-box"
          :class="checked ? 'checked' : ''"
          :style="checkboxColor !== '' && checked ? `border: 2px solid rgba(var(--vs-${checkboxColor}), 1);background: rgba(var(--vs-${checkboxColor}), 1);` : ''"
          @click="!rowSelectable ? setChecked() : ''">
      <span class="check-box--check">
        <vs-icon icon="check"/>
      </span>
    </span>

    <span class="check-box-label">
      <slot/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'customCheckBox',
  model: {
    prop: 'dataList',
    event: 'input'
  },
  props: {
    dataList: {
      type: Array,
      default () {
        return []
      }
    },
    value: {},
    checkboxColor: {
      type: String,
      default: 'primary'
    },
    multi: {
      type: Boolean,
      default: true
    },
    rowSelectable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: false
    }
  },
  created () {
    this.checked = this.value.hasOwnProperty('show') ? this.value.show : true
  },
  methods: {
    setChecked () {
      if (this.value && this.value.hasOwnProperty('locked') && this.value.locked) {
        this.checked = this.checked
      } else {
        this.checked = !this.checked
      }


      const list = this.dataList
      list.forEach((item) => {
        if ((item.hasOwnProperty('field') && item.field === this.value.field) || (item.hasOwnProperty('id') && item.id === this.value.id)) {
          item.show = this.checked
        } else if (!this.multi) {
          item.show = false
        }
      })
      this.$emit('input', list)
    }
  },
  watch: {
    dataList: {
      handler () {
        const list = this.dataList

        list.forEach((item) => {
          if ((item.hasOwnProperty('field') && item.field === this.value.field) || (item.hasOwnProperty('id') && item.id === this.value.id)) {
            this.checked = item.show
          }
        })
        // this.$emit('input', list)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-check-box {
  overflow: hidden;
  white-space: nowrap;

  .check-box {
    transition: all .2s ease;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 1px;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    transform: rotate(-90deg);
    //overflow: hidden;
    box-sizing: border-box;
    border: 2px solid rgb(180, 180, 180);

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .check-box--check {
      width: 20px;
      height: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .vs-icon {
        opacity: 0;
      }
    }
  }

  input:checked + .check-box {
    transform: rotate(0deg);
  }

  input:checked + .check-box .vs-icon {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .check-box-label {
    cursor: pointer;
    display: inline-flex;
    vertical-align: top;
    line-height: 20px;
    margin-left: 5px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

</style>
