<template>
  <vs-row class="filter-box" v-if="type === 'search'">
    <vs-col class="filter-icon-box">
      <vs-button v-if="filters.length > 0"
                 class="filter-icon p-0"
                 :class="[dialogStatus ? 'dialog-opened' : 'dialog-closed']"
                 @click="dialogStatus = true">
        <font-awesome-icon v-if="filter.type && filter.type.icon"
                           :icon="['fas', filter.type.icon]"/>
      </vs-button>
    </vs-col>

    <vs-col class="filter-search-box w-full">

      <input type="text"
             class="w-full filter-search text-center filter-search-input"
             :autocomplete="false"
             :name="Math.random()"
             @input="handleChangeInput"
             v-model="filter.search">

      <div v-if="filter.search"
           @click="filter.search = '', $emit('removeFilters', filter)"
           class="clear-search">

        <custom-icon icon="TIMES"
                     color="danger"
                     height="27px"
                     width="15px"/>
      </div>
    </vs-col>

    <div v-show="dialogStatus"
         class="filter-dialog"
         :class="[dialogStatus ? 'dialog-opened' : 'dialog-closed']">
      <ul>
        <li v-for="(type, type_index) in filters"
            :key="type_index"
            class="text-xs"
            @click="dialogStatus = false, filter.type = type">

<!--          {{ $t(type.i18n) || type.name }}-->

          <font-awesome-icon v-if="type.icon"
                             :icon="['fas', type.icon]"/>
        </li>
      </ul>
    </div>

    <div v-if="dialogStatus"
         class="filter-dialog-overlay"
         @click="dialogStatus = false"></div>
  </vs-row>

  <vs-row class="filter-box" v-else-if="type === 'date'">
    <vs-col class="filter-icon-box">
      <vs-button class="filter-icon p-0"
                 @click="$refs.datePicker.visible = true">
        <font-awesome-icon :icon="['fas', 'calendar']"/>
      </vs-button>
    </vs-col>

    <vs-col class="filter-search-box w-full">

      <input :id="`filterBoxSelectDate${field ? '_' + field : ''}`"
             @focus="$refs.datePicker.visible = true"
             type="text"
             class="w-full filter-search text-center"
             autocomplete="date"
             :name="Math.random()"
             v-model.lazy="filter.search">

      <div v-if="filter.search"
           @click="filter.search = '', $emit('removeFilters', filter)"
           class="clear-search">

        <custom-icon icon="TIMES"
                     color="danger"
                     height="27px"
                     width="15px"/>
      </div>
    </vs-col>

    <date-picker :element="`filterBoxSelectDate_${field ? '_' + field : ''}`"
                 ref="datePicker"
                 color="#222222"
                 :range="range"
                 :format="$localeValidator('moment.date')"
                 :locale="getLocale($store.state.setting.clubInfo.club_locale)"
                 :locale-config="localeConfig"
                 @input="filter.search = $event"
                 style="color: #3c3c3c !important;"/>
  </vs-row>

  <vs-row class="filter-box" v-else-if="type === 'select'">
    <vs-col class="filter-select-box">
      <custom-select :options="filters"
                     :default="defaultValue"
                     :searchable="filters.length > 5"
                     v-model.lazy="filter.search"/>
    </vs-col>
  </vs-row>

  <vs-row class="filter-box" v-else-if="type === 'checkbox'">

    <vs-col class="filter-icon-box">
      <vs-button class="filter-icon p-0"
                 @click="open = true">
        <font-awesome-icon :icon="['fas', 'check-square']"/>
      </vs-button>
    </vs-col>

    <vs-col class="filter-search-box w-full">
      <input type="text"
             class="w-full filter-search cursor-pointer text-center"
             :autocomplete="false"
             :name="Math.random()"
             :class="{'bottom-radius-zero': open}"
             @click="open = true"
             :value="filter.search.map(elm => { return elm.show ? elm.label : null }).filter(elm => { return elm != null }).join(', ')">
    </vs-col>

    <div class="filter-suggest-box"
         v-if="open">
      <ul>
        <li v-for="(fltr, fltr_index) in filters" :key="fltr_index">
          <custom-check-box class="w-full ltr"
                            :value="fltr"
                            v-model.lazy="filter.search">
            <vs-col>
              {{ fltr.label }}
            </vs-col>
          </custom-check-box>
        </li>
      </ul>
    </div>

    <div class="custom-select-overflow" v-if="open" @click="open = false"></div>
  </vs-row>
</template>

<script>
import CustomSelect from '@/components/customSelect/customSelect'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import CustomCheckBox from '@/components/customCheckBox/customCheckBox'
import CustomIcon from '../customIcon/customIcon'
import TimezoneService from '../../assets/js/timezones'
import 'lodash'

export default {
  name: 'filterBox',
  components: {
    CustomIcon,
    CustomCheckBox,
    CustomSelect,
    datePicker: VuePersianDatetimePicker
  },
  model: {
    prop: 'activeFilters',
    event: 'input'
  },
  props: {
    filters: {
      type: Array,
      default: () => { return [] }
    },
    field: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'search'
    },
    range: {
      type: Boolean,
      default: () => { return false }
    },
    activeFilters: {},
    defaultValue: ''
  },
  mixins: [TimezoneService],
  data () {
    return {
      timeout: 0,
      prevFilter: '',
      dialogStatus: false,
      filter: {
        search: '',
        type: {}
      },
      localeConfig: {
        fa: {
          dir: 'rtl',
          displayFormat: this.$localeValidator('moment.date'),
          lang: {
            label: 'FA',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        },
        ar: {
          dow: 0,
          dir: 'rtl',
          displayFormat: vm => {
            // vm.type = date | time | datetime | year | month | yearmonth
            switch (vm.type) {
            case 'date':
              return this.$localeValidator('moment.date')
            case 'datetime':
              return this.$localeValidator('moment.dateTime')
            case 'year':
              return this.$localeValidator('moment.year')
            case 'month':
              return this.$localeValidator('moment.month')
            case 'yearmonth':
              return this.$localeValidator('moment.yearMonth')
            case 'time':
              return this.$localeValidator('moment.time')
            }
          },
          lang: {
            label: 'AR',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        }
      },
      open: false
    }
  },
  created () {
    if (this.filters.length > 0) {
      this.filter.type = this.filters[0]
    }

    if (this.defaultValue) {
      this.filter.search = this.defaultValue
    }

    if (this.type === 'checkbox') {
      this.filter.search = this.filters || []
    }
    this.prevFilter = JSON.stringify(this.filter)
  },
  methods: {
    handleChangeInput: _.debounce(function () {this.handleEmitFilter()}, 1300, {leading:false, trailing:true}),
    handleEmitFilter () {
      if (this.prevFilter !== JSON.stringify(this.filter)) {
        if (this.filter.search.length === 0) {
          this.$emit('removeFilters', this.filter)
        } else {
          this.$emit('input', this.filter)
        }

        this.prevFilter = JSON.stringify(this.filter)
      }
    }
  },
  watch: {
    filters: {
      handler () {
        if (this.type === 'checkbox') {
          this.filter.search = this.filters
        }
      },
      deep: true
    },
    filter: {
      handler (val) {
        if (this.prevFilter === '' || this.prevFilter !== JSON.stringify(val)) {
          if (this.type !== 'search' && JSON.stringify(val) !== this.prevFilter) {
            if (val.search.length === 0) {
              this.$emit('removeFilters', val)
            } else if (this.type !== 'checkbox') {
              this.$emit('input', this.filter)
            } else if (JSON.parse(this.prevFilter).search.length > 0) {
              this.$emit('input', this.filter)
            }
            this.prevFilter = JSON.stringify(val)
          }
        }
      },
      deep: true
    },
    defaultValue: {
      handler (val) {
        if (val) {
          this.filter.search = val
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.filter-box {
  position: relative;

  .filter-search-box {
    position: relative;

    .filter-type-icon {
      position: absolute;
      margin: auto;
      padding: 3px 0 3px 3px;
      width: 25px;
      height: 70%;
      top: 0;
      bottom: 0;
      right: 7px;
      cursor: auto;
      color: #ffffff;
    }

    .filter-search {
      direction: ltr;
      padding: 0 7px 0 30px;
      margin: auto;
      border-radius: 3px;
      border: none !important;
      line-height: 41px;
      height: 41px;

      &.bottom-radius-zero {
        border-radius: .5rem .5rem 0 0 !important;
      }
    }

    .clear-search {
      position: absolute;
      display: block;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      height: fit-content;
    }
  }

  .filter-icon-box {
    width: fit-content !important;
    height: fit-content !important;
    position: absolute;
    top: 5px;
    //bottom: 0;
    left: 5px;
    margin: auto;
    z-index: 1000;

    .filter-icon {
      background: #555555 !important;
      width: 30px !important;
      height: 30px !important;
      box-shadow: none !important;
      padding: 0 !important;

      /*&.dialog-opened {
        background: #555555 !important;
        border-radius: 3px 3px 0 0;
      }*/
    }
  }

  .filter-dialog {
    width: 30px;
    margin-top: 5px;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: .5rem;
    z-index: 11000;

    ul {

      li {
        cursor: pointer;
        position: relative;
        direction: rtl;
        padding-left: 25px;
        line-height: 30px;
        height: 30px;
        transition: all .2s ease;
        overflow: hidden;
        white-space: nowrap;
        border-top: 1px solid #ffffff80;

        &:first-child {
          border-top: none;
        }

        svg {
          width: 20px !important;
          height: 30px !important;
          position: absolute;
          top: 0;
          left: 5px;
          padding: 0 4px;
          //margin-right: 10px;
        }

        &:hover {
          background: #cecece28;
        }
      }
    }
  }

  .filter-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
  }

  .filter-suggest-box {
    //margin-top: 5px;
    width: 100%;
    max-height: 200px;
    z-index: 11000;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #cecece;
    border-top: none;
    border-radius: 0 0 .5rem .5rem;


    &::-webkit-scrollbar {
      width: 1px;
    }

    ul {
      width: 100%;

      li {
        color: #2d3744;
        font-weight: 400;
        padding: 10px;
      }
    }
  }

  .useral-custom-element-select {
    margin-top: 5px;
    height: 41px !important;
    line-height: 39px !important;
    min-width: unset !important;

    .selected {
      border-radius: 3px !important;

      .useral-custom-element-input {
        line-height: 39px !important;
        text-align: left !important;
      }

      &:after {
        border-color: #cecece transparent transparent transparent;
        top: 15px !important;
      }

      &.open {
        border-radius: .5rem .5rem 0px 0px !important;
      }
    }

    .items {
      border-radius: 0px 0px .5rem .5rem !important;
    }
  }

  .custom-select-overflow {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
  }
}
</style>
