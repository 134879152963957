import axios from '@/http/axios'

// avatars
export function setAvatar (data) {
  return axios({
    url: 'v1/upload-avatar',
    data,
    method: 'post'
  })
}
