<template>
  <div class="insert-address">

    <vs-row>
      <vs-col>
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.name') }}
          <span class="text-danger">*</span>
        </div>

        <vs-input class="w-full"
                  :danger="validation.name.length > 0"
                  :danger-text="validation.name"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.name"/>
      </vs-col>
    </vs-row>

    <vs-row v-if="countries.length > 0">
      <vs-col>
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.country') }}
          <span class="text-danger">*</span>
        </div>

        <custom-select :options="countries"
                       :default="newAddress.country || countries[0]"
                       v-model="newAddress.country"
                       :danger="validation.country !== ''"
                       @suggest:selected="refreshProvince"
                       searchable/>
      </vs-col>
    </vs-row>

    <vs-row v-if="provinces.length > 0">
      <vs-col class="md:w-1/2 md:pr-1">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.province') }}
          <span class="text-danger">*</span>
        </div>
        <custom-select :options="provinces"
                       :default="newAddress.province || provinces[0]"
                       v-model="newAddress.province"
                       :danger="validation.province !== ''"
                       searchable/>
        <span class="text-xs text-danger">{{validation.province}}</span>
      </vs-col>

      <vs-col class="md:w-1/2 md:pl-1">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.city') }}
          <span class="text-danger">*</span>
        </div>
        <vs-input class="w-full"
                  :danger="validation.city.length > 0"
                  :danger-text="validation.city"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.city.value"/>
        <span class="text-xs text-danger">{{validation.city}}</span>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.address') }}
          <span class="text-danger">*</span>
        </div>

        <vs-input class="w-full"
                  :danger="validation.address.length > 0"
                  :danger-text="validation.address"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.address"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <!-- <vs-col class="md:w-1/3 md:pr-1">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.plaque') }}
        </div>

        <vs-input class="w-full"
                  :danger="validation.plaque.length > 0"
                  :danger-text="validation.plaque"
                  v-model="newAddress.plaque"/>
      </vs-col> -->

      <vs-col class="md:w-full">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.postalCode') }}
        </div>

        <vs-input class="w-full"
                  :danger="validation.postalCode.length > 0"
                  :danger-text="validation.postalCode"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.postalCode"/>
      </vs-col>
    </vs-row>

    <!--<vs-row>
      <vs-col class="md:w-full mt-2">
        <vs-checkbox class="text-sm" v-model="newAddress.direct">حضوری</vs-checkbox>
      </vs-col>
    </vs-row>-->

    <vs-row>
      <vs-col class="mt-5">
        <div class="w-full">{{ $t('profile.address.labels.receiver.info') }}</div>
        <vs-divider class="my-2"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col class="md:w-1/2 md:pr-1">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.receiver.name') }}
          <span class="text-danger">*</span>
        </div>

        <vs-input class="w-full"
                  :danger="validation.receiver.name.length > 0"
                  :danger-text="validation.receiver.name"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.receiver.name"/>
      </vs-col>

      <vs-col class="md:w-1/2 md:pl-1">
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.receiver.family') }}
          <span class="text-danger">*</span>
        </div>

        <vs-input class="w-full"
                  :danger="validation.receiver.lastName.length > 0"
                  :danger-text="validation.receiver.lastName"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="newAddress.receiver.lastName"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <div class="w-full text-sm">
          {{ $t('profile.address.labels.receiver.phoneNumber') }}
          <span class="text-danger">*</span>
        </div>

        <u-phone-input class="w-full"
                       label=""
                       :default-country-code="clubInfo.club_default_country_code"
                       v-model="newAddress.receiver.phoneNumber"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-spacer/>

      <vs-col class="mt-2 md:ml-2 md:w-2/5" v-if="this.address.name">
        <vs-button class="w-full text-white"
                   color="danger"
                   @click="$emit('address:deleted')">
          <span>{{ $t('profile.address.labels.delete') }}</span>
        </vs-button>
      </vs-col>

      <vs-col class="mt-2 ml-2 md:w-2/5 useral-action-button">
        <vs-button id="insertAddressBTN"
                   class="w-full text-white"
                   color="danger"
                   @click="sendData">
          <span>{{ $t('profile.address.labels.save') }}</span>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'
import UPhoneInput from "../customInput/UPhoneInput";
import {getCountries, getProvinces} from "../../http/requests/provinces";

export default {
  name: 'insertAddress',
  components: {UPhoneInput, CustomSelect},
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    address: {},
    data: {}
  },
  computed: {
    ...mapGetters({
      clubInfo: 'setting/getClubInfo'
    })
  },
  data () {
    return {
      firstModified: true,
      notModifiled: true,
      countries: [],
      provinces: [],
      newAddress: {
        name: '',
        address: '',
        plaque: '',
        postalCode: '',
        country: {
          label: '-',
          value: '-'
        },
        province: {
          label: '-',
          value: '-'
        },
        city: {
          isValid: true,
          value: ''
        },
        direct: false,
        receiver: {
          name: '',
          lastName: '',
          phoneNumber: {
            value: '',
            isValid: false
          }
        }
      },
      validation: {
        name: '',
        address: '',
        plaque: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        receiver: {
          name: '',
          lastName: '',
          phoneNumber: ''
        }
      }
    }
  },
  created () {
    this.getCountries()
    this.firstModified = true
    if (this.address) {

      this.newAddress.name = this.address.name ? this.address.name : ''
      this.newAddress.address = this.address.address ? this.address.address : ''
      this.newAddress.plaque = this.address.plaque ? this.address.plaque : ''
      this.newAddress.postalCode = this.address.postalCode ? this.address.postalCode : ''
      this.newAddress.country = this.address.country ? this.address.country : {value: '-', label: '-'}
      this.newAddress.province = this.address.province ? this.address.province : this.provinces[0]
      this.newAddress.city = this.address.city ? this.address.city : {value: '', isValid: true}
    }

    if (this.address && this.address.receiver) {
      this.newAddress.receiver.name = this.address.receiver.name
      this.newAddress.receiver.lastName = this.address.receiver.lastName
      this.newAddress.receiver.phoneNumber.value = this.address.receiver.phoneNumber
    }

    if (this.address.country && this.address.country.value > 0) {
      this.getProvinces()
    }

    setTimeout(() => {
      this.firstModified = false
    }, 200)
  },
  methods: {
    getCountries () {
      getCountries().then(response => {
        const countries = response.data.data

        this.countries = countries.map(country => {
          return {
            label: this.$t(`countries.${country.slug}.name`),
            slug: country.slug,
            value: country.id
          }
        })
      })
    },
    getProvinces () {
      getProvinces(this.newAddress.country.value).then(response => {
        const provinces = response.data.data

        this.provinces = provinces.map(province => {
          return {
            label: this.$t(`countries.${this.newAddress.country.slug}.provinces.${province.slug}`),
            value: province.id
          }
        })
      })
    },
    sendData () {
      this.nameValidator()
      this.addressValidator()
      this.cityValidator()
      this.provinceValidator()
      // if (this.newAddress.plaque.length > 0)
      // this.plaqueValidator()
      if (this.newAddress.postalCode.length > 0) this.postalCodeValidator()
      this.receiverNameValidator()
      this.receiverLastNameValidator()
      this.receiverPhoneNumberValidator()

      if (this.notModifiled) {
        this.$emit('notModifiled')
      }


      if (!this.validation.name &&
        !this.validation.address &&
        !this.validation.province &&
        !this.validation.city &&
        this.validation.city.value !== '' &&
        (!this.validation.postalCode || this.newAddress.postalCode.length === 0) &&
        !this.validation.receiver.name &&
        !this.validation.receiver.lastName &&
        this.newAddress.receiver.phoneNumber.isValid) {

        this.newAddress = {
          ...this.newAddress,
          receiver: {
            ...this.newAddress.receiver,
            phoneNumber: this.newAddress.receiver.phoneNumber.value
          }
        }

        this.$emit('input', this.newAddress)
      }
    },
    cityValidator () {
      if (this.newAddress.city.value.trim() === '') {
        this.validation.city = this.$t('profile.address.validators.city')
      } else {
        this.validation.city = ''
      }
    },
    provinceValidator () {
      if (this.newAddress.province.label.trim() === '') {
        this.validation.province = this.$t('profile.address.validators.province')
      } else {
        this.validation.province = ''
      }
    },
    nameValidator () {
      this.validation.name = this.$validator('regex.address.name').test(this.newAddress.name) ? '' : this.$t('profile.address.validators.name')
      if (this.newAddress.name.length === 0) {
        this.validation.name = this.$t('profile.address.validators.nameCantEmpty')
      }
    },
    addressValidator () {
      // eslint-disable-next-line no-useless-escape
      this.validation.address = this.$validator('regex.address.address').test(this.newAddress.address) ? '' : this.$t('profile.address.validators.address')
      if (this.newAddress.address.length === 0) {
        this.validation.address = this.$t('profile.address.validators.addressCantEmpty')
      }
    },
    plaqueValidator () {
      this.validation.plaque = this.$validator('regex.address.plaque').test(this.newAddress.plaque) ? '' : this.$t('profile.address.validators.plaque')
    },
    postalCodeValidator () {
      this.validation.postalCode = this.$validator('regex.address.postalCode').test(this.newAddress.postalCode) ? '' : this.$t('profile.address.validators.postalCode')
      if (this.newAddress.postalCode.length === 0) {
        this.validation.postalCode = ''
      }
    },
    receiverNameValidator () {
      this.validation.receiver.name = this.$validator('regex.user.name').test(this.newAddress.receiver.name) ? '' : this.$t('profile.address.validators.receiver.name')
      if (this.newAddress.receiver.name.length === 0) {
        this.validation.receiver.name = this.$t('profile.address.validators.receiver.nameCantEmpty')
      }
    },
    receiverLastNameValidator () {
      this.validation.receiver.lastName = this.$validator('regex.user.name').test(this.newAddress.receiver.lastName) ? '' : this.$t('profile.address.validators.receiver.family')
      if (this.newAddress.receiver.lastName.length === 0) {
        this.validation.receiver.lastName = this.$t('profile.address.validators.receiver.familyCantEmpty')
      }
    },
    receiverPhoneNumberValidator () {
      // this.validation.receiver.phoneNumber = this.$validator('regex.user.phoneNumber').test(this.newAddress.receiver.phoneNumber) ? '' : this.$t('profile.address.validators.phoneNumber')
      // if (this.newAddress.receiver.phoneNumber.length === 0) {
      //   this.validation.receiver.phoneNumber = this.$t('profile.address.validators.receiver.phoneNumber')
      // }
    },
    refreshProvince () {
      this.getProvinces()
      this.newAddress.province = {
        label: '-',
        value: ''
      }
    }
  },
  watch: {
    'newAddress': {
      handler () {
        if (!this.firstModified) {
          this.notModifiled = false
        }
      },
      deep: true
    },
    'newAddress.name': {
      handler () {
        this.nameValidator()
      },
      deep: true
    },
    'newAddress.address': {
      handler () {
        this.addressValidator()
      },
      deep: true
    },
    'newAddress.city': {
      handler (val) {
        if (val.value.trim() !== '') {
          this.cityValidator()
        }
      },
      deep: true
    },
    'newAddress.province': {
      handler (val) {
        if (val && val.label.trim() !== '') {
          this.provinceValidator()
        }
      },
      deep: true
    },
    'newAddress.plaque': {
      handler () {
        this.plaqueValidator()
      },
      deep: true
    },
    'newAddress.postalCode': {
      handler (val) {
        if (val.length > 10) this.newAddress.postalCode = this.newAddress.postalCode.substr(0, 10)
        this.postalCodeValidator()
      },
      deep: true
    },
    'newAddress.receiver.name': {
      handler () {
        this.receiverNameValidator()
      },
      deep: true
    },
    'newAddress.receiver.lastName': {
      handler () {
        this.receiverLastNameValidator()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
