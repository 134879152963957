import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getProducts: createCancelTokenHandler('getProducts'),
  getShreddedProducts: createCancelTokenHandler('getShreddedProducts'),
  getProductSaleTurnover: createCancelTokenHandler('getProductSaleTurnover'),
  getProductVariantSaleTurnover: createCancelTokenHandler('getProductVariantSaleTurnover'),
  getProductStoreroomTurnover: createCancelTokenHandler('getProductStoreroomTurnover'),
  getProductVariantStoreroomTurnover: createCancelTokenHandler('getProductVariantStoreroomTurnover'),
  getProductInventory: createCancelTokenHandler('getProductInventory'),
  getProductsTrash: createCancelTokenHandler('getProductsTrash'),
  getCategoryProductsList: createCancelTokenHandler('getCategoryProductsList'),
  getAllCategoryAttributes: createCancelTokenHandler('getAllCategoryAttributes'),
  getAttributes: createCancelTokenHandler('getAttributes')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getProducts (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProducts'].handleRequestCancellation().token
  })
}

export function getShreddedProducts (page, filters = [], sorts = [], stock = false) {
  let queries = makeQuery(page, filters, sorts)

  if (stock) {
    queries.push('stock=true')
  }

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/shredded-products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getShreddedProducts'].handleRequestCancellation().token
  })
}

export function getProduct (id) {

  return axios({
    url: `v1/products/${id}`,
    method: 'get'
  })
}

export function getProductStatus (id) {

  return axios({
    url: `v1/products/${id}/status`,
    method: 'get'
  })
}

export function getProductSaleTurnover (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/products/${id}/sale-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductSaleTurnover'].handleRequestCancellation().token
  })
}

export function getProductVariantSaleTurnover (productId, variantId, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/products/${productId}/variants/${variantId}/sale-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductVariantSaleTurnover'].handleRequestCancellation().token
  })
}

export function getProductStoreroomTurnover (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/products/${id}/storeroom-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductStoreroomTurnover'].handleRequestCancellation().token
  })
}

export function getProductVariantStoreroomTurnover (productId, variantId, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/products/${productId}/variants/${variantId}/storeroom-turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductVariantStoreroomTurnover'].handleRequestCancellation().token
  })
}

export function getProductInventory (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/storerooms-inventory/${id}${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductInventory'].handleRequestCancellation().token
  })
}

export function getProductWithCode (code) {

  return axios({
    url: `https://sport.useral.com/api/v1/admin/products?filters[code][text]=${code}&filters[code][type]=2&with_details=true`,
    method: 'get'
  })
}

export function insertProduct (payload) {

  return axios({
    url: 'v1/products',
    data: payload,
    method: 'post'
  })
}

export function getProductsTrash (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/products?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductsTrash'].handleRequestCancellation().token
  })
}

export function editProduct (id, payload) {

  return axios.patch(`v1/products/${id}`, payload)
}

export function setProductPrice (variantId, payload) {

  return axios.patch(`v1/products-pricing/${variantId}`, payload)
}

export function deleteProduct (id, force = false) {

  return axios({
    url: `v1/products/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreProduct (id) {

  return axios({
    url: `v1/products/${id}/restore`,
    method: 'put'
  })
}

export function uploadProductImage (image) {

  const data = new FormData()
  data.append('image', image)

  return axios({
    url: 'v1/admin/products/upload-image',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post'
  })
}

/* publish products */
export function publishProducts (payload) {
  return axios({
    url: 'v1/pending-products',
    data: payload,
    method: 'post'
  })
}

/* crud functions for categories */
export function getCategories (all = false) {

  return axios({
    url: `v1/categories${all ? '?all=true' : ''}`,
    method: 'get'
  })
}
export function getCategoriesWithoutChildren (id) {

  return axios({
    url: `v1/categories/${id}/without-children`,
    method: 'get'
  })
}

export function getCategory (id) {

  return axios({
    url: `v1/categories/${id}`,
    method: 'get'
  })
}

export function getCategoryProductsList (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/categories/${id}/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCategoryProductsList'].handleRequestCancellation().token
  })
}

export function getSuggestedCategoryAttributes (id) {

  return axios({
    url: `v1/categories/${id}/suggested-attributes`,
    method: 'get'
  })
}

export function getAllCategoryAttributes (categoryId, filters, type=[]) {
  let queries = makeQuery(1, filters, type)
  if (queries.length > 0) queries = `?${  queries.join('&')}`


  return axios({
    url: `v1/categories/${categoryId}/assignable-attributes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAllCategoryAttributes'].handleRequestCancellation().token
  })
}

export function createCategory (payload) {

  const data = {
    name: payload.name,
    parent_id: payload.parent_id,
    attrs: payload.attributes
  }

  return axios({
    url: 'v1/categories',
    data,
    method: 'post'
  })
}

export function updateCategory (payload) {

  const data = {
    id: payload.id,
    name: payload.name,
    parent_id: payload.parent_id,
    attrs: payload.properties || payload.attributes
  }

  return axios.patch(`v1/categories/${data.id}`, data)
}

export function deleteCategory (id) {

  return axios({
    url: `v1/categories/${id}`,
    method: 'delete'
  })
}
/* crud functions for categories */


/* crud functions for attributes  */

export function getAttributes (page, all = false) {
  let queries = makeQuery(page)

  if (all) {
    queries.push('all=true')
  }

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/attributes${queries ? queries : ''}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributes'].handleRequestCancellation().token
  })
}

export function insertCategoryAttribute (payload) {
  const category_id = payload.categoryId
  delete payload.category_id
  return axios({
    url: `v1/categories/${category_id}/attributes`,
    data: payload,
    method: 'post'
  })
}

export function deleteAttributeValue (id) {

  return axios({
    url: `v1/attribute-values/${id}`,
    method: 'delete'
  })
}
/* crud functions for attributes */


/* crud functions for brands */
export function getBrands () {

  return axios({
    url: 'v1/brands',
    method: 'get'
  })
}

export function getBrandAttributes (id) {

  return axios({
    url: `v1/brands/${id}/properties`,
    method: 'get'
  })
}

export function createBrand (payload) {

  const data = {
    name: payload.name,
    parent_id: payload.parent_id,
    properties: payload.properties || payload.attributes
  }

  return axios({
    url: 'v1/brands',
    data,
    method: 'post'
  })
}

export function updateBrand (payload) {

  const data = {
    id: payload.id,
    name: payload.name,
    properties: payload.properties || payload.attributes
  }

  return axios.patch('v1/brands', data)
}

export function deleteBrand (payload) {

  const data = {
    id: payload.id,
    parent_id: payload.parent_id
  }

  return axios({
    url: 'v1/brands',
    data,
    method: 'delete'
  })
}
/* /crud functions for brands */

