<template>
  <div class="insert-attributes-box">
    <vs-row>
      <vs-input class="attribute-name w-full"
                :label="$t('attributes.labels.name')"
                :danger="newAttributes.name.length > 14"
                :danger-text="newAttributes.name.length > 14 ? $t('attributes.validators.name', {num: 14}) : ''"
                :autocomplete="false"
                :name="Math.random()"
                @keyup.enter="goToNextField(0)"
                @input="handleNameChange"
                v-model="newAttributes.name"/>

      <vs-input class="attribute-name w-full"
                :label="$t('attributes.labels.slug')"
                :autocomplete="false"
                :name="Math.random()"
                @keyup.enter="goToNextField(0)"
                v-model="newAttributes.slug"/>

      <label class="attribute-input-label">
        <input type="checkbox" v-model="newAttributes.archive" />
        {{ `${$t('attributes.labels.archive')}` }}
      </label>
    </vs-row>

    <vs-row v-if="checkUserPermissions('attribute_value.show') && false">
      <div class="values-box w-full">
        <div class="values-header">
          <div class="header">{{ $t('attributes.labels.values') }}</div>

          <div class="mx-1"
               v-if="checkUserPermissions('attribute_value.create')"
               @click="selectedValue = {}, attributeValuePromptStatus = true">
            <custom-icon icon="PLUS"
                         color="success"
                         width="20px"
                         height="20px"
                         :cursor-pointer="true"/>
          </div>
        </div>
        <ul class="values">
          <li v-for="(value, value_index) in newAttributes.values"
              :key="value_index"
              @click="handleClickValues(value)">
            {{ value.value }}
          </li>
        </ul>
      </div>

      <!-- insert / edit value prompt -->
      <vs-prompt
        class="p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="attributeValuePromptStatus">

        <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
          <vs-row>
            <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
              <div @click="handleClick('saveBTN')">
                <custom-icon icon="SAVE" color="success"/>
              </div>
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
              {{ selectedValue.hasOwnProperty('id') ? $t('attributes.labels.editValue') : $t('attributes.labels.insertValue') }}
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
              <div @click="attributeValuePromptStatus = false">
                <custom-icon icon="TIMES-CIRCLE" color="danger"/>
              </div>
            </vs-col>
          </vs-row>
        </div>

        <div class="prompt-content py-2 px-3">
          <template>
            <keep-alive>
              <update-attribute-value v-if="selectedValue.hasOwnProperty('id')"
                                      :attribute-id="attribute.id"
                                      :value="selectedValue"
                                      :shops="newAttributes.shops"
                                      @updated="updateAttributeValue($event)"/>
              <insert-attribute-value v-else
                                      :attribute-id="attribute.id"
                                      :shops="newAttributes.shops"
                                      @inserted="insertAttributeValue($event)"/>
            </keep-alive>
          </template>
        </div>

      </vs-prompt>
    </vs-row>

    <vs-row v-if="!attribute.id">
      <div class="categories-box w-full">
        <div class="w-full">{{ $t('attributes.labels.categories') }}</div>

        <template v-for="(category, category_index) in categories">
          <custom-tree :node="category.node"
                       :children="category.children"
                       :key="category_index"
                       :config="treeConfig"
                       :actions="category.actions"
                       v-model="newAttributes.categories"/>
        </template>
      </div>
    </vs-row>

    <vs-row v-if="!attribute.id">
      <div class="categories-box w-full">
        <div class="w-full">{{ $t('attributes.labels.shops') }}</div>

        <custom-tree v-for="(shop, shop_index) in shops"
                     :key="shop_index"
                     :node="shop.node"
                     :config="treeConfig"
                     :actions="shop.actions"
                     v-model="newAttributes.shops"/>
      </div>
    </vs-row>

    <vs-row class="mt-2">
      <vs-button v-if="attribute && attribute.id > 0 && checkUserPermissions('attribute.delete')"
                 class="w-full"
                 color="danger"
                 @click="$refs.deleteConfirmation.showDialog()">{{ $t('attributes.labels.delete') }}
      </vs-button>
    </vs-row>

    <vs-button id="saveAttributesBTN" class="useral-action-button" @click="sendData()"/>

    <custom-dialog ref="deleteConfirmation"
                   :body="$t('attributes.confirmations.delete.body', {name: attribute.name})"
                   @accept="deleteAttribute"/>
  </div>
</template>

<script>
import {deleteAttributeValue, getCategories} from '@/http/requests/products'
import {
  deleteAttribute,
  getAttribute,
  insertAttribute, insertAttributeValue,
  updateAttribute,
  updateAttributeValue
} from '@/http/requests/attributes'
import CustomDialog from '@/components/customDialog/customDialog'
import {getShops} from '@/http/requests/plugins/wooreceiver'
import CustomIcon from '@/components/customIcon/customIcon'
import InsertAttributeValue from '@/views/admin/products/Attributes/values/insertAttributeValue'
import UpdateAttributeValue from '@/views/admin/products/Attributes/values/updateAttributeValue'
import {checkUserPermissions} from "../../assets/js/functions";
import CustomTree from "../Tree/CustomTree.vue";

export default {
  name: 'insertAttributes',
  components: {
    CustomTree,
    UpdateAttributeValue,
    InsertAttributeValue,
    CustomIcon,
    CustomDialog
  },
  model: {
    prop: 'attribute',
    event: 'attribute:inserted'
  },
  props: {
    attribute: {},
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      attributeValuePromptStatus: false,
      selectedValue: {},
      insertAttributeValueTimer: 0,
      addValueTimer: 0,
      copyAttributes: {},
      newAttributes: {
        name: '',
        slug: '',
        archive: true,
        values: [],
        categories: [],
        shops: []
      },
      categoryTreeOptions: {
        type: 'insertCategory'
      },
      shopTreeOptions: {
        type: ''
      },
      shops: [],
      treeConfig: {
        actions: [],
        collapse: true,
        selectable: true,
        parentSelectable: false
      },
      noCategory: {
        node: {
          id: 4,
          label: this.$t('attributes.labels.withoutCategory'),
          show: true
        },
        children: [],
        actions: {
          insert: false,
          update: false,
          delete: false
        }
      },
      categories: []
    }
  },
  created () {
    this.getAttribute()
    this.getCategoriesList()
    this.getShops()

    this.generateTreeActions()

    this.newAttributes.categories.push(this.noCategory.node)

    if (this.categoryId > 0 && this.newAttributes.categories.indexOf(this.categoryId) === -1) {
      this.newAttributes.categories.push(this.categoryId)
    }

    /*if (this.attribute.categories.length > 0) {
      this.attribute.categories.forEach((category) => {
        this.newAttributes.categories.push(category.id)
      })
    }*/
  },
  methods: {
    getShops () {
      getShops(1, ['status=3']).then(response => {
        const shops = response.data.data

        shops.forEach((shop) => {
          this.shops.push({
            node: {
              id: shop.id,
              label: shop.name
            },
            children: [],
            actions: {
              insert: false,
              update: false,
              delete: false
            }
          })
        })

        if (shops.length === 1) {
          this.newAttributes.shops = [shops[0].id]
        }
      })
    },
    generateTreeActions () {
      if (checkUserPermissions('role.create')) {
        this.treeConfig.actions.push({
          key: 'insert',
          icon: 'PLUS',
          color: 'success',
          event: 'insert'
        })
      }
      if (checkUserPermissions('role.update')) {
        this.treeConfig.actions.push({
          key: 'update',
          icon: 'EDIT',
          color: 'warning',
          event: 'update'
        })
      }
      if (checkUserPermissions('role.delete')) {
        this.treeConfig.actions.push({
          key: 'delete',
          icon: 'TRASH',
          color: 'danger',
          event: 'delete'
        })
      }
    },
    handleNameChange () {
      this.newAttributes.slug = this.newAttributes.name.toLowerCase().replaceAll(' ', '-')
    },
    getCategoriesList () {
      // this.categories = []
      getCategories().then(response => {
        const categories = response.data.data
        this.categories = []
        categories.forEach(category => {
          this.categories.push(this.getCategories(category, true))
        })
      })
    },
    getCategories (category) {
      const data = {
        node: {
          id: category.id,
          label: category.name,
          show: false
        },
        children: [],
        actions: {
          insert: false,
          update: false,
          delete: false
        }
      }

      if (category.children) {
        category.children.forEach((child) => {
          data.children.push(this.getCategories(child))
        })
      }

      this.newAttributes.categories.push(data.node)

      return data
    },
    goToNextField (index) {
      document.getElementsByTagName('input')[index + 1].focus()
    },
    handleTypeAttributeValue (index) {
      if (this.newAttributes.values[index].value && this.newAttributes.values[index].value.trim().length > 0) {
        this.addValue()
        this.setInputWidth(index)
        // this.handleInsertAttributeValue(index)
      } else {
        if (this.hasEmptyInput() <= 1) {
          this.moveFocus(index)
        }

        this.handleRemoveAttributeValue(index)
      }
    },
    handleInsertAttributeValue (index) {
      if (this.attribute && !this.newAttributes.values[index].id) {
        clearTimeout(this.insertAttributeValueTimer)
        this.insertAttributeValueTimer = setTimeout(() => {
          insertAttributeValue({
            id: this.attribute.id,
            value: this.newAttributes.values[index].value
          }).then(() => {

          })
        }, 400)
      }
    },
    handleRemoveAttributeValue (index) {
      if (this.newAttributes.values[index].id) {
        deleteAttributeValue(this.newAttributes.values[index].id).then(() => {
          /*this.$vs.notify({
            title: 'Alert',
            text: 'OK',
            time: 2400,
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })*/
        })
      }

      if (this.hasEmptyInput() > 1) {
        this.newAttributes.values.splice(index, 1)
      }
    },
    setInputWidth (index) {
      let width = this.newAttributes.values[index].length * 8 > 100 ? this.newAttributes.values[index].length * 8 : 100
      width = width > 200 ? 200 : width
      const element = document.getElementsByTagName('input')[index + 1]
      element.setAttribute('style', `width: ${width}px !important`)
    },
    hasEmptyInput () {
      let counter = 0
      this.newAttributes.values.forEach((value) => {
        if (value.value.trim().length === 0) {
          counter++
        }
      })
      return counter
    },
    moveFocus (index) {
      if (index > 0) {
        document.getElementsByTagName('input')[index].focus()
      }
    },
    addValue () {
      if (this.newAttributes.values.map((elm) => {
        return elm.value
      }).indexOf(' ') === -1) {
        clearTimeout(this.addValueTimer)
        this.addValueTimer = setTimeout(() => {
          this.newAttributes.values.push({value: ' '})
        }, 500)
      } else {
        // document.getElementsByTagName('input')[this.newAttributes.values.indexOf(' ') + 1].focus()
      }
    },
    getAttribute () {
      if (this.attribute.id > 0) {
        this.newAttributes = {
          name: this.attribute.name || '',
          slug: this.attribute.slug || '',
          archive: this.attribute.has_archives,
          values: this.attribute.values || [],
          categories: this.attribute.categories.map(elm => {
            return elm.id
          }) || [],
          shops: this.attribute.shops || []
        }

        if (this.attribute.hasOwnProperty('id')) {
          this.newAttributes.id = this.attribute.id
        }

        // if (this.hasEmptyInput() === 0) this.newAttributes.values.push({value: ' '})
        this.copyAttributes = JSON.parse(JSON.stringify(this.newAttributes))
      }
    },
    refreshAttribute () {
      if (this.attribute && this.attribute.id) {
        getAttribute(this.attribute.id).then(response => {
          const attribute = response.data.data

          this.newAttributes = {
            id: this.attribute.id,
            name: attribute.name || '',
            values: attribute.values || [],
            categories: attribute.categories.map(elm => {
              return elm.id
            }) || [],
            shops: attribute.shops || []
          }

          this.copyAttributes = JSON.parse(JSON.stringify(this.newAttributes))
        })
      }
    },
    sendData () {
      if (this.newAttributes.name.length > 30) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.name', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (this.newAttributes.slug.length > 30) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slug', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (this.newAttributes.slug.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slugRequired'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.attribute && this.attribute.id > 0) {
        if (JSON.stringify(this.newAttributes) !== JSON.stringify(this.copyAttributes)) {
          /*const values = []
          this.newAttributes.values.forEach((value) => {
            if (value.value.trim()) {
              values.push({
                id: value.id || null,
                value: value.value.trim()
              })
            }
          })*/

          const attribute = {
            id: this.newAttributes.id,
            name: this.newAttributes.name,
            slug: this.newAttributes.slug,
            has_archives: this.newAttributes.archive,
            // values,
          }
          attribute.categories = this.newAttributes.categories.filter((category) => {
            return category.show === true
          }).map((category) => {
            return category.id
          })

          updateAttribute(attribute).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('attributes.edit.notifications.edit.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })

            this.$store.dispatch('helper/changeAttribute')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('attributes.edit.notifications.edit.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('attributes.validators.noChange'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      } else {
        /*const values = []
        this.newAttributes.values.forEach((value) => {
          if (value.value.trim()) {
            values.push({value: value.value.trim()})
          }
        })*/

        const attribute = {
          name: this.newAttributes.name,
          slug: this.newAttributes.slug,
          has_archives: this.newAttributes.archive,
          shops: this.newAttributes.shops
        }
        attribute.categories = this.newAttributes.categories.filter((category) => {
          return category.show === true
        }).map((category) => {
          return category.id
        })

        insertAttribute(attribute).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('attributes.insert.notifications.insert.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/changeAttribute')
        }).catch(() => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('attributes.insert.notifications.insert.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
      }
    },
    insertAttributeValue (value) {
      const data = {
        value: value.value,
        shops: this.newAttributes.shops
      }
      insertAttributeValue(this.attribute.id, data).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.insert.notifications.insertValue.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeAttributeValues')
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.insert.notifications.insertValue.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    updateAttributeValue (value) {
      const data = {
        value: value.value
      }
      updateAttributeValue(value.id, data).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.edit.notifications.editValue.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeAttributeValues')
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.edit.notifications.editValue.error'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    deleteAttribute () {
      deleteAttribute(this.attribute.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.delete.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })
      }).catch(error => {
        switch (error.response.status) {
        case 409:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('attributes.delete.validators.delete'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

        default:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('attributes.delete.notifications.delete.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break
        }
      })

      this.$store.dispatch('helper/changeAttribute')
    },
    handleClickValues (value) {
      this.selectedValue = value
      if (checkUserPermissions('attribute_value.update')) {
        this.attributeValuePromptStatus = true
      }
    },
    checkUserPermissions (permission) {
      return checkUserPermissions(permission)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.attributeValuesChanged': {
      handler (val) {
        if (val) {
          this.attributeValuePromptStatus = false
          this.refreshAttribute()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.insert-attributes-box {

  input.attribute-name {
    padding: .7rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #cecece;
  }


  .attribute-input-label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: rgba(0,0,0,.7);

    input[type=checkbox] {
      margin-right: 5px;
      height: 15px;
      width: 15px;
    }
  }

  .values-box,
  .categories-box {
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;

    input {
      border: 1px solid #cecece;
      border-radius: 5px;
      padding: 0 10px;
      line-height: 30px;
      margin: 2px;

      &:focus {
        transition: all ease .2s;
        background: transparent !important;
        border: 1px solid #131314;
      }
    }
  }

  .values-box {
    .values-header {
      display: flex;

      .header {
        flex-grow: 1;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        text-align: center;
        min-width: 75px;
        margin: 2px;
        padding: 5px;
        background: #ededed;
        border-radius: .5rem;
        cursor: pointer;
      }
    }
  }
}
</style>
