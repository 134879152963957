<template>
  <div class="accordion-list">
      <div class="accordion-list-wrapper">
        <div class="accordion-list-searchbar-box w-full">
          <input type="text" class="accordion-list-searchbar" v-model="search">
          <font-awesome-icon icon="search"/>
        </div>
        <accordion-list-content class="px-0"
                                :parent-data="getParentData(data_row)"
                                :children="data_row.children || []"
                                v-model="selectedData"
                                :type="type"
                                :editable="editable"
                                @item:changed="$emit('list:changed')"
                                @item:clicked="returnSelectedItem"
                                v-for="(data_row, data_row_index) in data"
                                :key="data_row_index"/>
      </div>
  </div>
</template>

<script>
import AccordionListContent from '@/components/accordionList/AccordionListContent'

export default {
  name: 'AccordionList',
  data () {
    return {
      selectedData: {},
      search: '',
      typingTimer: 0
    }
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    data: {},
    type: '',
    editable: true
  },
  components: {
    AccordionListContent
  },
  methods: {
    getParentData (data) {
      const parent = {}
      Object.keys(data).forEach((key) => {
        if (key !== 'childrens') parent[key] = data[key]
      })
      return parent
    },
    getSearchResultFromChildrenData (data) {
      const searchResult = data
      if (data.childrens.length === 0 && searchResult.name.indexOf(this.search) === -1) {
        searchResult.show = false
      }
      const result = []
      data.childrens.forEach((row) => {
        const rowSearchResult = this.getSearchResultFromChildrenData(row)
        if (rowSearchResult) result.push(rowSearchResult)
      })
      searchResult.childrens = result
      return searchResult
    },
    clearSearchResultFromChildrenData (data) {
      const searchResult = data
      const result = []
      searchResult.show = true
      data.childrens.forEach((row) => {
        const rowSearchResult = this.clearSearchResultFromChildrenData(row)
        if (rowSearchResult) result.push(rowSearchResult)
      })
      searchResult.childrens = result
      return searchResult
    },
    returnSelectedItem () {
      setTimeout(() => {
        this.$emit('list:clicked', this.selectedData)
      }, 100)
    }
  },
  watch: {
    search: {
      handler (val) {
        if (!val.length) {
          // clear search results
          const result = []
          this.data.forEach((row) => {
            const rowSearchResult = this.clearSearchResultFromChildrenData(row)
            if (rowSearchResult) result.push(rowSearchResult)
          })
          this.$emit('input', result)

        } else {
          // search in list
          clearTimeout(this.typingTimer)
          this.typingTimer = setTimeout(() => {
            const result = []
            this.data.forEach((row) => {
              const rowSearchResult = this.getSearchResultFromChildrenData(row)
              if (rowSearchResult) result.push(rowSearchResult)
            })
            this.$emit('input', result)
          }, 500)

        }
      }
    }
  }
}
</script>

<style lang="scss">
.accordion-list-wrapper {
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0 10px;

  &::-webkit-scrollbar {
    width: 1px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #10163a;
  }

  .accordion-list-searchbar-box {
    position: relative;

    .accordion-list-searchbar {
      width: 100%;
      display: block;
      margin: 10px auto 15px;
      border: none;
      border-radius: 5px;
      line-height: 35px;
      padding: 0 10px 0 30px;
    }

    svg {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
    }
  }
}
</style>
