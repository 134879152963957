<template>
  <div class="accordion-list-insert-item">
    <vs-row>
      <vs-col>
        <vs-input class="w-full"
                  v-model="newItem.name"
                  :autocomplete="false"
                  :name="Math.random()"
                  :label-placeholder="$t(`products.insert.accordionList.${type}.insert.${type}`)"/>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="flex my-3">
        <p>{{ $t(`products.insert.accordionList.attributes`) }}</p>
        <vs-divider></vs-divider>
        <button class="insert-attribute-button useral-gradient-color-success" @click="insertAttributesDialogStatus = true">
          <font-awesome-icon icon="plus"/>
        </button>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="my-3">
        <ul class="item-attributes">
          <li class="flex" v-for="(attribute, attribute_index) in newItem.attributes" :key="attribute_index">
            <span class="attribute-name w-5/12 inline-block">{{attribute.name}}:</span>
            <span class="attribute-values w-5/12 inline-block">{{ getAttributeValues(attribute_index) }}</span>

            <button class="value-action-button useral-gradient-color-warning w-1/12 inline-block" @click="setAttributeToEdit(attribute_index)">
              <font-awesome-icon icon="pen"/>
            </button>
            <button class="value-action-button useral-gradient-color-danger w-1/12 inline-block"
                    @click="newItem.attributes.splice(attribute_index, 1)">
              <font-awesome-icon icon="trash"/>
            </button>
          </li>
        </ul>
      </vs-col>
    </vs-row>

    <!-- insert attribute dialog -->
    <vs-prompt
      color="success"
      :accept-text="$t(`products.insert.accordionList.buttons.accept`)"
      :cancel-text="$t(`products.insert.accordionList.buttons.cancel`)"
      @cancel="cancelAction"
      @close="cancelAction"
      @accept="handleAction"
      :title="$t(`products.insert.accordionList.insertAttribute.title`)"
      :is-valid="checkAttributeIsValid"
      class="accordion-list-insert-item-attribute-dialog"
      :active.sync="insertAttributesDialogStatus">
      <accordion-list-insert-item-attribute v-model="attribute"/>
    </vs-prompt>
    <!-- /insert attribute dialog -->

    <!-- edit attribute dialog -->
    <vs-prompt
      color="success"
      :accept-text="$t(`products.insert.accordionList.buttons.accept`)"
      :cancel-text="$t(`products.insert.accordionList.buttons.cancel`)"
      @cancel="cancelAction"
      @close="cancelAction"
      @accept="handleAction"
      :title="$t(`products.insert.accordionList.editAttribute.title`)"
      :is-valid="checkAttributeIsValid"
      class="accordion-list-insert-item-attribute-dialog"
      :active.sync="editAttributesDialogStatus">
      <accordion-list-insert-item-attribute v-model="attribute"/>
    </vs-prompt>
    <!-- /edit attribute dialog -->
  </div>
</template>

<script>
import AccordionListInsertItemAttribute from '@/components/accordionList/AccordionListInsertItemAttribute'


export default {
  name: 'AccordionListInsertItem',
  components: {
    AccordionListInsertItemAttribute
  },
  model: {
    prop: 'initData',
    event: 'input'
  },
  props: {
    initData: {},
    type: ''
  },
  data () {
    return {
      insertAttributesDialogStatus: false,
      editAttributesDialogStatus: false,
      status: 'insert',
      attributeIndexForEdit: 0,
      attribute: {
        id: 'new',
        name: '',
        values: []
      },
      newItem: {
        name: '',
        attributes: []
      }
    }
  },
  computed: {
    checkAttributeIsValid () {
      if (this.attribute.name.length >= 3 && this.attribute.values.length) return true
      return false
    }
  },
  created () {
    if (this.initData) {
      this.initItem()
      this.newItem.parent_id = this.initData.parent_id
    }
  },
  methods: {
    initItem () {
      if (this.initData && this.initData.name && (this.initData.properties || this.initData.attributes)) {
        this.newItem.id = this.initData.id
        this.newItem.name = this.initData.name
        this.newItem.attributes = this.initData.properties
      }
    },
    handleAction () {
      switch (this.status) {
      case 'insert':
        this.newItem.attributes.push(this.attribute)
        this.attribute = {
          id: 'new',
          name: '',
          values: []
        }
        break

      case 'edit':
        this.newItem.attributes[this.attributeIndexForEdit] = this.attribute
        this.attributeIndexForEdit = 0
        this.attribute = {
          id: 'new',
          name: '',
          values: []
        }
        this.status = 'insert'
        break
      }
    },
    cancelAction () {
      this.status = 'insert'
      this.attribute = {
        id: 'new',
        name: '',
        values: []
      }
    },
    setAttributeToEdit (index) {
      this.status = 'edit'
      this.attributeIndexForEdit = index
      this.attribute = this.newItem.attributes[index]
      this.editAttributesDialogStatus = true
    },
    getAttributeValues (index) {
      let values = ''
      this.newItem.attributes[index].values.forEach((value) => {
        if (values) values += ', '
        values += value.name
      })
      return values
    }
  },
  watch: {
    newItem: {
      handler () {
        this.$emit('input', this.newItem)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-list-insert-item {

  .vs-divider {
    padding: 0;
    margin: 0 10px;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    width: auto !important;

    .vs-divider-border {
      border-color: #10163a !important;
    }
  }

  .insert-attribute-button, .value-action-button {
    width: 25px !important;
    height: 25px;
    line-height: 25px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }

  .value-action-button {
    margin: 0 2px;

    &:nth-child(1) {
      margin-left: 10px;
    }
  }

  ul.item-attributes {

    li {
      border-bottom: 1px solid #10163a;
      padding: 5px 0;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 25px;

        &.attribute-name {

        }

        &.attribute-values {
        }
      }
    }
  }
}
</style>
