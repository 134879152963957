<template>
  <div class="useral-custom-element-select" :class="classes" :tabindex="tabindex" @blur="open = false">
    <label v-if="label">{{ label }}</label>
    <div class="selected" :class="{ 'open': open, 'disabled': disabled, 'danger': danger }" :style="styles" @click="setSelectOpenPosition">

      <vs-icon class="cancel-search"
               icon="icon-x"
               icon-pack="feather"
               v-if="searchable && search"
               @click="search = '', !disabled ? open = true : ''"/>

      <input v-if="searchable"
             type="text"
             class="useral-custom-element-input w-full p-0"
             :autocomplete="false"
             :name="Math.random()"
             @input="$emit('searched', $event)"
             @click="!disabled ? open = true : ''"
             v-model="search">

      <div @click="!disabled ? open = !open : ''"
           v-else>
        {{ typeof selected === 'string' ? JSON.parse(selected).label : selected.label }}
      </div>
    </div>

    <div class="items" :class="[{ selectHide: !open }, `position-${selectOpenPosition}`]">
      <div
        v-for="(option, i) of options"
        :key="i"
        v-show="!option.hasOwnProperty('show') || option.show"
        @click="
          selected = option,
          open = false,
          $emit('input', option),
          $emit('suggest:selected', option),
          search = option.label
        ">
        {{ typeof option === 'string' ? JSON.parse(option).label : option.label }}
      </div>
    </div>

    <div class="custom-select-overflow" v-if="open" @click="open = false"></div>
  </div>
</template>

<script>
export default {
  name: 'customSelect',
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      selected: this.default ? this.default : this.options.length > 0 ? this.options[0] : null,
      open: false,
      selectOpenPosition: 'bottom',
      search: ''
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: () => ''
    },
    classes: {
      type: Array,
      default: () => []
    },

    styles: {
      type: String,
      default: () => ''
    },
    default: {},
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    searchable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // this.$emit("input", this.selected);
    this.search = this.selected.label
    if (this.default) this.search = this.default.label
  },
  methods: {
    setSelectOpenPosition() {
      const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;
      const hasEnoughSpaceBelow = spaceBelow > 200;
      if (hasEnoughSpaceBelow) {
        this.selectOpenPosition = 'bottom';
      } else {
        this.selectOpenPosition = 'top';
      }
    }
  },
  watch: {
    default: {
      handler () {
        if (this.default && this.default.label !== '') {
          this.selected = this.default

          if (this.searchable) {
            this.search = this.default.label === '-' ? '' : this.default.label
          }
        }
      },
      deep: true
    },
    search: {
      handler (val) {
        if (this.searchable) {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          if (!this.firstVisit) {
            // this.open = true

            this.options.forEach((option) => {

              option.show = option.label.toString().indexOf(val) !== -1
            })
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.useral-custom-element-select {
  position: relative;
  width: 100%;
  min-width: 100px;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;

  label {
    font-size: 12px;
    position: absolute;
    z-index: 10000;
    top: -8px;
    left: 8px;
    line-height: 13px;
    padding: 0 5px;
    background: #fff;
  }


  .selected {
    position: relative;
    background-color: #555555;
    border-radius: 6px;
    min-height: 35px;
    border: 1px solid #cdcdcd;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    z-index: unset !important;
    transition: background-color .3s ease;

    input {
      padding-left: 14px !important;
      transition: background-color .3s ease;
    }

    .cancel-search {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 7px;
      margin: auto;
      display: block;
      height: fit-content;
    }

    &.open {
      //border: 1px solid #10163a;
      //box-shadow: 0px 5px 10px black;
      background-color: #131314;
      border-radius: 6px 6px 0 0;
      z-index: 200 !important;

      input {
        background-color: #131314 !important;
      }
    }

    &.disabled {
      opacity: 0.75;
    }

    &.danger {
      border: 1px solid #b00000 !important;
    }
  }

  .selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid;
    border-color: #fff transparent transparent transparent;
  }

  .items {
    color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    //border-right: 1px solid #10163a;
    //border-left: 1px solid #10163a;
    //border-bottom: 1px solid #10163a;
    box-shadow: 0 5px 10px -5px black;
    position: absolute;
    background-color: #131314;
    left: 0;
    right: 0;
    z-index: 11000;
    max-height: 250px;

    &::-webkit-scrollbar {
      display: block;
      border-radius: .5rem;
      width: 10px;
      background: #f9f9fd;
      right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: .2rem;
      background: #cecece;
    }

    &.position-bottom {
      //top: calc(100%);
      border-radius: 0 0 6px 6px;
    }

    &.position-top {
      top: unset !important;
      bottom: calc(100%);
      border-radius: 6px 6px 0 0;
    }

    div {
      color: #fff;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
      border-top: 1px solid #333333;

      &:hover {
        transition: all 1s cubic-bezier(0.39, 0.58, 0.57, 1);
        background-color: #333333;
      }
    }
  }

  .custom-select-overflow {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
  }
}

.selectHide {
  display: none;
}
</style>
