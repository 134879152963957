<template>
  <div class="accordion-list-insert-item-attribute">
    <vs-row>
      <vs-col>
        <vs-input class="w-full"
                  v-model="newAttribute.name"
                  :autocomplete="false"
                  :name="Math.random()"
                  :label-placeholder="$t(`products.insert.accordionList.insertAttribute.attribute`)"/>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="flex my-3">
        <p>{{ $t(`products.insert.accordionList.insertAttribute.values`) }}</p>
        <vs-divider></vs-divider>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="flex my-3">
        <vs-input class="attribute-values-input w-10/12"
                  @keydown.enter="handleAction"
                  :autocomplete="false"
                  :name="Math.random()"
                  v-model="value"/>
        <button class="insert-attribute-value-button useral-gradient-color-success mx-auto"
                @click="handleAction">
          <font-awesome-icon icon="plus"/>
        </button>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col class="my-3">
        <span class="attribute-values-item"
              v-for="(value, value_index) in newAttribute.values"
              :key="value_index">
          {{ value.name }}
        <button class="value-action-button useral-gradient-color-warning" @click="setValueToEdit(value_index)">
          <font-awesome-icon icon="pen"/>
        </button>
        <button class="value-action-button useral-gradient-color-danger"
                @click="newAttribute.values.splice(value_index, 1)">
          <font-awesome-icon icon="trash"/>
          </button>
        </span>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: 'AccordionListInsertItemAttribute',
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    data: {}
  },
  data () {
    return {
      value: '',
      valueIndexForEdit: 0,
      status: 'insert',
      newAttribute: {
        name: '',
        values: []
      }
    }
  },
  created () {
    this.initValues()
  },
  methods: {
    initValues () {
      if (this.data && this.data.name && this.data.values.length) {
        this.newAttribute.id = this.data.id
        this.newAttribute.name = this.data.name
        this.newAttribute.values = this.data.values
      }
    },
    setValueToEdit (index) {
      this.status = 'edit'
      this.valueIndexForEdit = index
      this.value = this.newAttribute.values[index].name
    },
    handleAction () {
      switch (this.status) {
      case 'insert':
        if (this.value.length > 3) {
          this.newAttribute.values.push({id: 'new', name: this.value})
          this.value = ''
        }
        break

      case 'edit':
        if (this.value.length > 3) {
          this.newAttribute.values[this.valueIndexForEdit].name = this.value
          this.valueIndexForEdit = 0
          this.value = ''
          this.status = 'insert'
        }
        break
      }
    }
  },
  watch: {
    newAttribute: {
      handler () {
        if (!this.newAttribute.id) this.newAttribute.id = 'new'
        this.$emit('input', this.newAttribute)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-list-insert-item-attribute {

  .vs-divider {
    padding: 0;
    margin: 0 10px;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    width: auto !important;

    .vs-divider-border {
      border-color: #10163a !important;
    }
  }

  .insert-attribute-value-button, .value-action-button  {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }

  .value-action-button {
    margin: 0 2px;

    &:nth-child(1) {
      margin-left: 10px;
    }
  }

  .attribute-values-input {
    input {
      height: 25px;
    }
  }

  .attribute-values-item {
    display: inline-flex;
    padding: 7px 5px 7px 15px;
    border-radius: 10px;
    border: 1px solid #10163a;
    margin: 0 5px 5px 0;
    height: 40px;
    line-height: 25px;
  }
}
</style>
