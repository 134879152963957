import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getAttributes: createCancelTokenHandler('getAttributes'),
  getAttributeProducts: createCancelTokenHandler('getAttributeProducts')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getAttributes (page, filters = []) {
  let queries = makeQuery(page, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/attributes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributes'].handleRequestCancellation().token
  })
}

export function getAllAttributes () {

  return axios({
    url: 'v1/attributes?type=all',
    method: 'get'
  })
}

export function getAvailableAttributes (filters = [], sorts = []) {
  let queries = makeQuery(1, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/available-attributes${queries}`,
    method: 'get'
  })
}

export function getAttribute (id) {
  return axios({
    url: `v1/attributes/${id}`,
    method: 'get'
  })
}

export function getAttributeProducts (id, page, filters = [], sorts=[]) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/attributes/${id}/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributeProducts'].handleRequestCancellation().token
  })
}

export function insertAttribute (attribute) {
  return axios({
    url: 'v1/attributes',
    data: attribute,
    method: 'post'
  })
}

export function setAttributePriorities (attribute) {
  return axios({
    url: 'v1/attributes-priorities',
    data: attribute,
    method: 'post'
  })
}

export function updateAttribute (payload) {

  return axios.patch(`v1/attributes/${payload.id}`, payload)
}

export function insertAttributeValue (attributeId, data) {

  return axios({
    url: `v1/attributes/${attributeId}/values`,
    data,
    method: 'post'
  })
}

export function updateAttributeValue (valueId, data) {

  return axios.patch(`v1/attribute-values/${valueId}`, data)
}

export function deleteAttributeValue (valueId) {

  return axios({
    url: `v1/attribute-values/${valueId}`,
    method: 'delete'
  })
}

export function deleteAttribute (id) {

  return axios({
    url: `v1/attributes/${id}`,
    method: 'delete'
  })
}
