import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
  getStoreroomsInventory: createCancelTokenHandler('getStoreroomsInventory'),
  getStoreroomsInventoryValuation: createCancelTokenHandler('getStoreroomsInventoryValuation'),
  getStores: createCancelTokenHandler('getStores'),
  getStoresTrash: createCancelTokenHandler('getStoresTrash'),
  getStoreTurnover: createCancelTokenHandler('getStoreTurnover'),
  getStoreroomReceipts: createCancelTokenHandler('getStoreroomReceipts')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getStoreroomsInventory (page = 1, filters = [], sorts = [], storeroom_id = 0) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/storerooms-inventory${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreroomsInventory'].handleRequestCancellation().token
  })
}

export function getStoreroomsInventoryValuation (page = 1, filters = [], sorts = [], storeroom_id = 0) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  if (storeroom_id > 0) {
    return axios({
      url: `v1/storerooms/${storeroom_id}/inventory-valuation${queries}`,
      method: 'get',
      // setting a cancel token and canceling the previous request of this type
      cancelToken: cancelTokenHandlerObject['getStoreroomsInventoryValuation'].handleRequestCancellation().token
    })
  } else {
    return axios({
      url: `v1/storerooms-inventory-valuation${queries}`,
      method: 'get',
      // setting a cancel token and canceling the previous request of this type
      cancelToken: cancelTokenHandlerObject['getStoreroomsInventoryValuation'].handleRequestCancellation().token
    })
  }

}

export function getStores (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/storerooms${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStores'].handleRequestCancellation().token
  })
}

export function getStoresTrash (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${queries.join('&')}`
  return axios({
    url: `v1/storerooms?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoresTrash'].handleRequestCancellation().token
  })
}

export function getStore (id) {
  return axios({
    url: `v1/storerooms/${id}`,
    method: 'get'
  })
}

export function getStoreTurnover (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/storerooms/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreTurnover'].handleRequestCancellation().token
  })
}

export function insertStore (payload) {
  return axios({
    url: 'v1/storerooms',
    data: payload,
    method: 'post'
  })
}

export function editStore (payload) {
  return axios.patch(`v1/storerooms/${payload.id}`, payload)
}

export function deleteStore (id, force = false) {
  return axios({
    url: `v1/storerooms/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreStore (id) {
  return axios({
    url: `v1/storerooms/${id}/restore`,
    method: 'put'
  })
}

export function getStoreroomReceipts (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/storeroom-receipts${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreroomReceipts'].handleRequestCancellation().token
  })
}

export function getStoreroomReceipt (id) {
  return axios({
    url: `v1/storeroom-receipts/${id}`,
    method: 'get'
  })
}

export function acceptStoreroomReceipt (id) {
  return axios({
    url: `v1/storeroom-receipts/${id}/accept`,
    method: 'post'
  })
}

export function getStoreroomReceiptAddress (id) {
  return axios({
    url: `v1/storeroom-receipts/${id}/address`,
    method: 'get'
  })
}

export function insertStoreroomReceipt (payload) {
  return axios({
    url: 'v1/storeroom-receipts',
    data: payload,
    method: 'post'
  })
}

export function editStoreroomReceipt (payload) {
  return axios.patch(`v1/storeroom-receipts/${payload.id}`, payload)
}

export function exportStoreInventory (id) {
  return axios({
    url: `v1/storerooms/${id}/export-inventory`,
    method: 'get',
    responseType: 'blob',
  })
}
